<template>
    <div>
        <div class="img-cloud" id="img-cloud">
            <div>
                <v-row >
                    <v-col cols="12" sm="9" class="text-home">
                        <h1 class="big-title">Améliorez votre suivi de santé avec Biotag Cloud</h1>
                    </v-col>
                </v-row>
            </div>
        </div>
        <v-row>
            <v-col cols="12" sm="1" md="1" lg="1"></v-col>
            <v-col cols="12" sm="10" md="4" lg="4" class="mt100 mb100" >
                <div id="le-biotagsensor" class="trais"></div>
                <h1 class="green-title">Biotag Cloud</h1>
                <p class="text-classi">L'offre Biotag Cloud de Biotag Performance est une plateforme logicielle dédiée à la surveillance de la santé en temps réel. Conçue pour les utilisateurs de notre implant connecté Biotag Performance ou Biotag Care, elle permet de stocker et de visualiser en temps réel les données collectées par l'implant. <br><br>
                Elle offre également des fonctionnalités avancées telles que la création d'alertes, l'extraction de données sous forme de fichiers .csv, la visualisation en temps réel avec une courbe de streaming de données, et la visualisation des données historiques. <br><br>
                Cette plateforme est un outil essentiel pour suivre en temps réel votre état de santé et prendre des décisions éclairées pour votre bien-être. Elle est accessible via la page "espace client" du site Biotagsensor.com pour les utilisateurs ayant souscrit à l'abonnement Biotag Cloud.
                </p>
            </v-col>
            <v-col cols="12" sm="1" lg="2"></v-col>
            <v-col cols="12" sm="10" lg="4">
                <div class="img-logi">
                        <iframe style="width: 100%; height: 400px;" src="https://www.youtube-nocookie.com/embed/KYFlFFbbYlI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </v-col>
        </v-row>
        <v-row >
            <v-col cols="12" sm="1" lg="1"></v-col>
            <v-col cols="12" sm="10" lg="4">
                <div class="video-logi">
                <iframe style="width: 100%; height: 400px;" src="https://www.youtube.com/embed/hitViMrZwrs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </v-col>
             <v-col cols="12" sm="1" lg="2"></v-col>
             <v-col cols="12" sm="10" lg="4" class="position-resp ">
                <h1 class="green-title">Biotag App : l'application android Biotagsensor</h1>
                <p class="text-classi">
                Biotag App est notre application Android dédiée au transfert et à l'observation des données. Elle se connecte en Bluetooth à la plateforme Biotag Cloud, vous permettant de suivre les déplacements et les données de santé de vos animaux via une interface graphique intuitive. Enfin, Biotag App facilite le transfert des données collectées vers votre compte Biotag Cloud, où vous pouvez les stocker et les analyser plus en détail.
            </p>
            </v-col>
            
        </v-row>
        <v-row class="utilisation ">
            <v-col cols="12" sm="2"></v-col>
            <v-col cols="12" sm="8">
                <v-row class="title-utilisation">
                    <h3> Qu'est-il possible de faire avec Biotag Cloud ?</h3>
                </v-row>
                <v-row class="parag-utilisation">
                    <div>
                        <img src="../assets/ico/suivitempsréelcouleur.png" alt="icône horloge">
                        <h5>Suivi temps réel</h5>
                        <p>Avec MiceApp, vous pouvez visualiser vos données en temps réel à tout moment et n'importe où avec une courbe de streaming de données et un tableau actualisé toutes les secondes.</p>
                    </div>
                    <div>
                        <img src="../assets/ico/alertecouleur.png" alt="icône cloche">
                        <h5>Création d'alertes</h5>
                        <p>MiceApp vous permet de définir des seuils pour des indicateurs importants, comme la fréquence cardiaque ou la température corporelle, afin de recevoir des alertes en temps réel en cas de dépassement de ces seuils.</p>
                    </div>
                    <div>
                        <img src="../assets/ico/historiquecouleur.png" alt="icône fichier loupe">
                        <h5>Suivi historique</h5>
                        <p>MiceApp vous permet de visualiser l'historique des données de santé de vos sujet d'études, ce qui vous permet de suivre les tendances sur une période déterminée, comme la semaine ou le mois précédent.</p>
                    </div>
                    <div>
                        <img src="../assets/ico/csvcouleur.png" alt="icône fichier csv">
                        <h5>Exportation de données</h5>
                        <p>MiceApp vous permet d'exporter des données sous forme de fichier .csv, ce qui vous permet de les utiliser pour votre propre analyse ou pour les partager.</p>                            </div>
                </v-row>
            </v-col>
            <v-col cols="12" sm="2"></v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="1" md="1" lg="1"></v-col>
            <v-col cols="12" sm="10" md="4" lg="4" class="mt100 mb100" >
                <div id="le-biotagsensor" class="trais"></div>
                <h1 class="green-title">Comment exporter des données avec Biotag Cloud ?</h1>
                <p class="text-classi"> 
Biotag Cloud vous donne la possibilité d'exporter les données de vos implants stockées sur le cloud sécurisé de BioTagSensor. Vous pouvez sélectionner l'implant dont vous souhaitez exporter les données, choisir les données spécifiques à exporter, ainsi que l'heure et la date de début et de fin de l'exportation. Une fois que vous avez chargé le fichier CSV, un bouton "Exporter" apparaîtra. Il vous suffira de cliquer dessus pour obtenir votre fichier !
                </p>
            </v-col>
            <v-col cols="12" sm="1" lg="2"></v-col>
            <v-col cols="12" sm="10" lg="4">
                <div class="img-logi">
                        <iframe style="width: 100%; height: 400px;" src="https://www.youtube.com/embed/7WREhg4hXkw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </v-col>
        </v-row>
         <v-row>
                <v-col cols="12" sm="1" md="1" lg="1"></v-col>
            <v-col cols="12" sm="10" md="4" lg="4" class="mt100 mb100" >
                    <h1 class="green-title">Vos données 100% sécurisées</h1>
                    <p class="text-classi">Nous avons mis en œuvre tous les moyens pour assurer la protection
                        de vos données personnelles : <br><br>
                        Chiffrement de bout en bout <br>
                        Audits de sécurité réguliers <br>
                        Respect de l’ensemble des dispositions du règlement général européen sur la protection des données (RGPD) <br>
                        Nos équipes continuent à maintenir ce niveau de sécurité au quotidien.
                    </p>
                    <router-link to="/contact" id="btn" style="left: 65%; top: 10%;">Contactez-nous</router-link>
                </v-col>
                 <v-col cols="12" sm="1" lg="2"></v-col>
            <v-col cols="12" sm="10" lg="4">
                    <div style="margin-top: 70px">
                        <img src="../assets/ico/données_sécurisées.png" alt="démo logiciel" class="ico">
                    </div>
                </v-col>
            </v-row>
            <foot/>
    </div>
</template>

<script>
import foot from '@/components/Footer.vue'
export default {
    components:{
      foot
    },
}
</script>

<style>

.img-cloud{
    background-size: cover;
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    text-align: left;
    position: relative;
}

#img-cloud{
    background-image: url(../images/courbes_logi.jpg);
}

.img-cloud::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 55, 109, 0.33);
}

.text-home {
    position: relative;
    top: 150px;
  }

.text-home h1{
    margin-left: 5%;
    width: 90%;
    margin-bottom: 5px;
}



.img-logi{
    margin-top: 150px;
}

.img-logi video{
    width: 130%;
    transition: transform 0.5s ease-in-out;
}

.video-logi{
    margin-top: 40px;
}

.utilisation{
    background-color: var(--vertfonce);
    padding: 50px 0px;
}

.title-utilisation{
    width: 100%;
    text-align: center;
    justify-content: center;
}

.title-utilisation h3{
    font-family: 'roboto' sans-serif;
    font-size: 35px;
    color: white;
    margin-bottom: 70px;
    width: 40%;
}

.parag-utilisation{
    display: flex;
    justify-content: space-between;
}

.parag-utilisation div{
    justify-content: left;
    width: 20%;
}

.parag-utilisation div img{
    width: 20%;
    margin-bottom: 35px;
}

.parag-utilisation div h5{
    font-family: 'roboto' sans-serif;
    font-size: 25px;
    color: white;
    margin-bottom: 40px;
    font-weight: 400;
}
.parag-utilisation div p{
    font-family: 'roboto' sans-serif;
    font-size: 18px;
    color: white;
    font-weight: 300;
}


.text-secu h1{
    width: 80%;
    margin-bottom: 50px;
}

.text-secu p{
    font-family: 'roboto' sans-serif;
    font-size: 20px;
    color: var(--violet);
}

.ico{
    width: 80%;
}

</style>
